import React, { useState } from 'react';
import { PartnerType } from '../index';

import Accordion from '../../../components/Accordion';

import './styles.scss';

type FaqTypes = {
  partnerPage: boolean;
  partnerInfo?: PartnerType;
};

function Faq({ partnerInfo, partnerPage = false }: FaqTypes) {
  const [selectedAccordionFaq, setSelectedAccordionFaq] = useState<number>(1);

  function toggleAccordionFaq(index: number) {
    if (selectedAccordionFaq === index) {
      return setSelectedAccordionFaq(null);
    }

    setSelectedAccordionFaq(index);
  }

  return (
    <section className="new-faq" id="faq">
      <div className={`container ${partnerPage ? 'partner-fgts' : ''}`}>
        <h5>TIRE SUAS DÚVIDAS</h5>
        <h2>Perguntas Frequentes</h2>

        <div>
          <Accordion
            id={1}
            title="O que é o Saldo FGTS?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(1)}
          >
            O FGTS é um saldo criado para proteger o trabalhador em situações específicas,
            <br />
            como demissão sem justa causa, compra de imóvel e aposentadoria.
            <br />
            O empregador deposita 8% do salário do trabalhador em uma conta vinculada mensalmente.
          </Accordion>
          <Accordion
            id={2}
            title="O que é o Saque-Aniversário do FGTS?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(2)}
          >
            O Saque-Aniversário é uma modalidade que permite retirar anualmente, no mês do aniversário, uma parte do saldo disponível no FGTS.
          </Accordion>
          <Accordion
            id={3}
            title="O que é a Antecipação do Saque-Aniversário?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(3)}
          >
            É um tipo de crédito que permite adiantar até 10 parcelas anuais do Saque-Aniversário 
            <br />
            de uma só vez. O saldo do FGTS é utilizado como garantia,
            <br />
            resultando em juros mais baixos do que em empréstimos convencionais.
          </Accordion>
          <Accordion
            id={4}
            title="Como a antecipação é paga?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(4)}
          >
            As parcelas são descontadas automaticamente do saldo do FGTS, no mês do aniversário do trabalhador. 
            <br />
            Você não precisa se preocupar com pagamentos mensais.
          </Accordion>
          <Accordion
            id={5}
            title="Quem pode contratar a antecipação?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(5)}
          >
            <ul>
              <li>Ter saldo no FGTS suficiente para a antecipação.</li>
              <li>Aderir ao Saque-Aniversário pelo app ou site do FGTS.</li>
            </ul>
          </Accordion>
          {partnerInfo?.name === 'modal' ||
          partnerInfo?.name === 'boxpag' ||
          partnerInfo?.name === 'vestbank' ||
          partnerInfo?.name === 'rainbow' ||
          partnerInfo?.name === 'winky' ||
          partnerInfo?.name === '7pay' ||
          partnerInfo?.name === 'oxebank' ||
          partnerInfo?.name === 'truckdigital' ||
          partnerInfo?.name === 'jpbank' ||
          partnerInfo?.name === 'quantabank' ||
          partnerInfo?.name === 'pawsbank' ||
          partnerInfo?.name === 'bancoatitude' ||
          partnerInfo?.name === 'bm2bank' ||
          partnerInfo?.name === 'smartpetro' ? (
            <Accordion
              id={6}
              title="Qual é o valor mínimo que eu posso antecipar do Saque-Aniversário?"
              selected={selectedAccordionFaq}
              toggleAccordion={() => toggleAccordionFaq(6)}
            >
              O valor mínimo de antecipação é de R$ 200,00.
            </Accordion>
          ) : (
            <Accordion
              id={7}
              title="Quais as taxas de juros?"
              selected={selectedAccordionFaq}
              toggleAccordion={() => toggleAccordionFaq(7)}
            >
              A taxa é competitiva e varia conforme o mercado. Atualmente, é de 1,80% ao mês para operações realizadas pelo app ou internet banking.
            </Accordion>
          )}
          <Accordion
            id={8}
            title="Quantas parcelas podem ser antecipadas?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(8)}
          >
            Você pode antecipar até 10 parcelas anuais de Saque-Aniversário.
          </Accordion>
          <Accordion
            id={9}
            title="É possível antecipar parcialmente?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(9)}
          >
            Sim, você pode escolher antecipar apenas uma parte do saldo disponível.
          </Accordion>
          <Accordion
            id={10}
            title="Qual é o valor bloqueado no FGTS?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(10)}
          >
            O saldo correspondente ao valor antecipado será bloqueado como garantia até a quitação total do contrato.
          </Accordion>
          <Accordion
            id={11}
            title="Quais são as vantagens da antecipação?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(11)}
          >
            <ul style={{listStyleType: 'circle'}}>
              <li>Taxas de juros reduzidas.</li>
              <li>Até 10 parcelas anuais antecipadas.</li>
              <li>Sem impacto mensal no orçamento.</li>
              <li>Pagamento automático diretamente do saldo do FGTS. “Você não vai precisar se preocupar com nenhum boleto!”</li>
            </ul>
          </Accordion>
          <Accordion
            id={12}
            title="Posso contratar mesmo estando negativado?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(12)}
          >
            Sim, você pode contratar mesmo com restrições no SERASA, ou seja, com o nome “sujo”.
          </Accordion>
          <Accordion
            id={13}
            title="Meus dados estão seguros?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(13)}
          >
            Sim, a operação é realizada em ambiente protegido e autorizado pela Caixa Econômica Federal, seguindo rigorosos padrões de segurança.
          </Accordion>
          <Accordion
            id={14}
            title="Como consultar meu saldo no FGTS?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(14)}
          >
            Você pode consultar pelo aplicativo FGTS, disponível para Android e iOS.
          </Accordion>
          <Accordion
            id={15}
            title="Como acompanhar minha antecipação?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(15)}
          >
            O status da proposta pode ser acompanhado pelo WhatsApp (21) 96577-3075.
          </Accordion>
          <Accordion
            id={16}
            title="É possível quitar antecipadamente?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(16)}
          >
            Sim, você pode quitar a dívida antecipadamente e desbloquear o saldo correspondente no FGTS.
          </Accordion>
          <Accordion
            id={17}
            title="É possível quitar antecipadamente?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(17)}
          >
            Sim, após a quitação do contrato, é possível alterar do Saque-Aniversário para o Saque-Rescisão. O prazo mínimo de carência para essa mudança é de 25 meses.
          </Accordion>
          <Accordion
            id={18}
            title="O que acontece se eu for demitido?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(18)}
          >
            O trabalhador terá direito a multa de 40% sobre o saldo total, e a modalidade de Saque-Aniversário se mantém até o fim do pagamento dos anos antecipados.
          </Accordion>
        </div>
      </div>
    </section>
  );
}

export default Faq;
